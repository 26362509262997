import React, { useContext, useMemo } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../components/context/BrinkContext"
import {
  containerMaxWidth,
  MEDIUM,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_LARGE,
} from "../constants"
import Layout from "../components/Layout"
import SecureLayout from "../components/SecureLayout"
import FilterGridProducts from "../components/filter/FilterGridProducts"
import ReactPlayer from "react-player"


const Hero = styled.div`
  position: relative;
`

const BackgroundImage = styled(GatsbyImage)`
  position:relative;
  max-width: 100%;
  width: 100%;

  div{
    max-width: 100% !important;
  }
`

const Content = styled.div`
  max-width: ${containerMaxWidth};
  margin: 8rem auto 0;
  color: ${(p) => p.theme.colors.white};
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;

  h1 {
    border-left: none;
  }

  ${MEDIA_MIN_MEDIUM} {
    margin: 10rem auto 0;
  }
`

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 0 auto 6rem;
  text-align: center;

  h1 {
    margin: 4rem 0 1rem;

    ${MEDIA_MIN_MEDIUM} {
      margin: 6rem 0 1rem;
    }
  }
`

const Title = styled.h1`
  text-align: center;
  padding: 0 1.3rem 0 1rem;
  line-height: 2.8rem;
  font-size: 2.8rem;
  letter-spacing: 0.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
  border-left: 0.3rem solid ${(p) => p.theme.colors.primary};
  display: inline-block;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 4rem;
    margin-bottom: 2rem;
  }
`

const Description = styled.h2`
  text-align: center;
  padding: 0 2rem;
  width: 100%;
  max-width: 70rem;
  line-height: 2rem;
  font-weight: 400;
  font-size: 1.3rem;
  margin: 0 auto;
  text-transform: initial;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.6rem;
    line-height: 2.8rem;
    margin: 0 auto;
  }
`

const VideoMobile = styled.div`
  display: block;

  ${MEDIA_MIN_MEDIUM} {
    display: block;
  }
`

const VideoDesktop = styled.div`
  display: none;

  ${MEDIA_MIN_MEDIUM} {
    display: block;
  }
`

const ImageContainer = styled.div`
  position: relative;

  video{
    object-fit: cover;
  }

`

const CollectionGridPage = ({ data: { sanityCollectionGridPage }, pageContext }) => {
  const { t } = useTranslation("translations")
  const { languageCode } = useContext(BrinkContext)
  const {
    pageTitle,
    pageDescription,
    intSlug,
    collections,
    mobileImage,
    desktopImage,
    desktopVideo,
    video,
    headerColorTheme
  } = sanityCollectionGridPage

  const images = useMemo(
    () =>
      withArtDirection(getImage(mobileImage.asset), [
        {
          media: `(min-width: ${MEDIUM}px)`,
          image: getImage(desktopImage.asset)
        }
      ]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const products = collections
    .filter((collection) => collection.products)
    .map((collection) => collection.products)
    .flat(1)
    .filter((p) => p.active && p.onlyVip !== true)
  const gender = intSlug[pageContext.buildLanguage].includes("dudes")
    ? "dudes"
    : intSlug[pageContext.buildLanguage].includes("ladies")
      ? "ladies"
      : "unisex"
  const loginEnabled = process.env.GATSBY_VIP_ENABLE_LOGIN === "true"
  const LayoutComponent = loginEnabled ? SecureLayout : Layout

  const hasHero = mobileImage && desktopImage

  //Dummy content, will be sanity content from collections
  const bannerText = "LOREM IPSUM"
  const hasImage = true
  const widgetTitle = "THE BLACK & ORANGE COLLECTION"

  const sources = hasHero
    ? [
      getImage(mobileImage.asset),
      {
        ...getImage(desktopImage.asset),
        media: `(min-width: ${MEDIUM}px)`
      }
    ]
    : null

  const description = pageDescription
    ? pageDescription[languageCode] || pageDescription.en
    : ""

  return (
    <LayoutComponent
      meta={{
        title: pageTitle[languageCode] || pageTitle.en,
        description: description
      }}
      pageContext={pageContext}
      absoluteHeader={hasHero}
      invertedHeader={!hasHero || headerColorTheme === "dark"}
    >
      {hasHero && (
        <Hero>
          <ImageContainer>
            {
              video ? (
                <VideoMobile><ReactPlayer
                  url={video}
                  playsinline={true}
                  width="100%"
                  height="100%"
                  muted={true}
                  loop={true}
                  controls={false}
                  className="background-video"
                  playing
                /></VideoMobile>
              ) : desktopVideo ? (<VideoDesktop><ReactPlayer
                url={desktopVideo}
                playsinline={true}
                width="100%"
                height="100%"
                muted={true}
                loop={true}
                controls={false}
                className="background-video"
                playing
              /></VideoDesktop>) :
                (
                  <BackgroundImage image={images} alt={""} loading="eager" />
                )
            }

          </ImageContainer>
          <Content>
            <Title>{pageTitle[languageCode] || pageTitle.en}</Title>
          </Content>
        </Hero>
      )}
      <Container>
        {!hasHero && (
          <>
            <Title>{pageTitle[languageCode] || pageTitle.en}</Title>{" "}
            {pageDescription?.en && (
              <Description>
                {pageDescription[languageCode] || pageDescription.en}
              </Description>
            )}
          </>
        )}
        <FilterGridProducts
          hasImage={hasImage}
          widgetTitle={collections[0].title}
          bannerText={bannerText}
          sectionTitle={collections[0].title}
          pageSlug={intSlug.en}
          pageTitle={pageTitle.en}
          collections={collections}
          collectionProducts={products}
          gender={gender === "unisex" ? "kids" : gender}
        />
      </Container>
    </LayoutComponent>
  )
}

export default CollectionGridPage

export const query = graphql`
  query ($collectionGridPageId: String!) {
    sanityCollectionGridPage(_id: { eq: $collectionGridPageId }) {
      _id
      pageTitle {
        en
      }
      pageDescription {
        en
      }
      slug {
        current
      }
      intSlug {
        en
      }
      headerColorTheme
      mobileImage {
        asset {
          gatsbyImageData(width: 900)
        }
      }
      desktopImage {
        asset {
          gatsbyImageData(width: 2800)
        }
      }
      video
      desktopVideo
      collections {
        ... on SanityImageCard {
          id
          video
          name
          columns
          _type
          imageFile {
            asset {
              url
            }
          }
        }
        ... on SanityCollection {
          title
          products {
            ... on SanityImageCard {
              id
              video
              name
              columns
              _type
              imageFile {
                asset {
                  url
                }
              }
            }
            ...ProductSimple
          }
        }

      }
    }
  }
`
